import { MainState } from './state'
import { getStoreAccessors } from 'typesafe-vuex'
import { State } from '../state'

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return state.userProfile?.is_superuser
  },
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
  jobResults: (state: MainState) => state.jobResults,
  labs: (state: MainState) => state.labs,
  labInvites: (state: MainState) => state.labInvites,
  analysisDataSummary: (state: MainState) => state.analysisDataSummary
}

const { read } = getStoreAccessors<MainState, State>('')

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer)
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer)
export const readHasAdminAccess = read(getters.hasAdminAccess)
export const readUserProfile = read(getters.userProfile)
export const readFirstNotification = read(getters.firstNotification)
export const readJobResults = read(getters.jobResults)
export const readLabs = read(getters.labs)
export const readLabInvites = read(getters.labInvites)
export const readAnalysisDataSummary = read(getters.analysisDataSummary)
