import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { MainState } from './state'

const defaultState: MainState = {
  userProfile: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  jobResults: {},
  labs: [],
  labInvites: [],
  analysisDataSummary: []
}

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters
}
