
import { Vue, Component } from "vue-property-decorator";

import { appName } from "@/env";
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readUserProfile,
} from "@/store/main/getters";
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from "@/store/main/mutations";
import { dispatchUserLogOut } from "@/store/main/actions";

@Component
export default class AppNav extends Vue {
  private expandAdmin: boolean = false;
  private adminItems = [
    {
      to: "/app/main/admin/users/all",
      icon: "group",
      title: "Users"
    },
    {
      to: "/app/main/admin/users/invite",
      icon: "person_add",
      title: "Invite User"
    },
    {
      to: "/app/main/admin/jobs",
      icon: "backup_table",
      title: "Analyses"
    },
    {
      to: "/app/main/admin/metrics",
      icon: "trending_up",
      title: "Metrics"
    },
  ]

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
}
