import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

const disallowedDomains = [
  '126.com',
  '163.com',
  'gmail.com',
  'googlemail.com',
  'hotmail.com',
  'hotmail.es',
  'hotmail.it',
  'live.ca',
  'mailbox.org',
  'mailinator.com',
  'msn.com',
  'myyahoo.com',
  'outlook.co.nz',
  'outlook.com',
  'outlook.it',
  'qq.com',
  'stikezz.com',
  'yahoo.co.in',
  'yahoo.co.uk',
  'yahoo.com',
  'yahoo.es',
  'yahoo.fr',
  'yandex.com'
] // should match tf

const disallowedTLDs = [
  '.cn',
  '.ru'
] // should match tf

Validator.extend('academicEmail', {
  getMessage: (field) =>
    'Only academic emails are allowed. Please sign up at https://bugseq.com/register.',
  validate: (value, args) =>
    !disallowedDomains.some((e) => value.endsWith(e)) && !disallowedTLDs.some((e) => value.endsWith(e))
})

Validator.extend('institutionEmail', {
  getMessage: (field) =>
    'Please use an email address affiliated with an institution',
  validate: (value, args) =>
    !disallowedDomains.some((e) => value.endsWith(e)) && !disallowedTLDs.some((e) => value.endsWith(e))
})

Validator.extend('knownKit', {
  getMessage: (field) =>
    'If your kit is not listed, please email support@bugseq.com',
  validate: (value, args) => value !== 'UNKNOWN'
})

Validator.extend('password', {
  getMessage: (field) =>
    'Password must be >8 characters and contain at least one of each: lowercase letter, uppercase letter, number, symbol',
  validate: (value, args) => {
    const lowercaseRegex = /[a-z]/
    const uppercaseRegex = /[A-Z]/
    const numberRegex = /[0-9]/
    const symbolRegex = /[^a-zA-Z0-9\s]/ // \s represents whitespace characters

    const hasLowercase = lowercaseRegex.test(value)
    const hasUppercase = uppercaseRegex.test(value)
    const hasNumber = numberRegex.test(value)
    const hasSymbol = symbolRegex.test(value)

    // Return true if all criteria are met, otherwise return false
    return value.length >= 8 && hasLowercase && hasUppercase && hasNumber && hasSymbol
  }
})

Vue.use(VeeValidate)
