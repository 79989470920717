export const formatTime = (time: Date | null | undefined): string | null => {
  if (time === null || time === undefined) {
    return null
  }
  return time.toLocaleString()
}

export const formatCurrency = (cents: number | null | undefined): string | null => {
  if (cents === undefined || cents === null) {
    return null
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return formatter.format(cents / 100)
}

export const formatSize = (bytes: number): string => {
  // https://stackoverflow.com/a/14919494
  const thresh = 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${bytes.toFixed(1)} ${units[u]}`
}

export const formatDuration = (seconds: number): string => {
  // https://stackoverflow.com/a/25279399
  const date = new Date(0)
  date.setSeconds(seconds)
  return date.toISOString().substring(11, 19)
}

export const copyToClipboard = async (text: string): Promise<void> => {
  // this returns a promise, maybe a caller would want
  // to action success/error
  //
  // also, typescript has a hard time with navigator.clipboard:
  // https://github.com/Microsoft/TypeScript/issues/26728
  await window.navigator.clipboard.writeText(text)
}

export const acceptedExtensions = [
  'fq',
  'fastq',
  'fq.gz',
  'fastq.gz',
  'bam'
]

export const parseS3Uri = (uri: string): { bucket: string, prefix?: string } => {
  const trimmed = uri.replace('s3://', '')
  const bucketSeparator = trimmed.indexOf('/')
  if (bucketSeparator === -1) {
    return { bucket: trimmed }
  }

  const [bucket, prefix] = [trimmed.slice(0, bucketSeparator), trimmed.slice(bucketSeparator + 1)]
  return { bucket, prefix }
}

const getAWSLinkWithRole = (linkWithoutRole: string, role: string): string => {
  const params = new URLSearchParams({
    account_id: '642025892278',
    role_name: role,
    destination: linkWithoutRole
  })
  // e.g. https://bugseq.awsapps.com/start/#/console?account_id=642025892278&role_name=prod_ro&destination=https%3A%2F%2Fs3.console.aws.amazon.com%2Fs3%2Fbuckets%2Fbugseq-prod-user-inputs-ca-central-1%3Fprefix%3Djob_inputs%252Fu_AACppK89gPFEbq2C9lKk4IJx%252Fanls_AABTmJTeerVJdoQo076o8rQy
  return `https://bugseq.awsapps.com/start/#/console?${params.toString()}`
}

export const getS3DashboardLink = (parsed: { bucket: string, prefix?: string }): string => {
  // some guesswork on the region
  let region = 'ca-central-1'
  if (parsed.bucket.includes('us-west-2')) {
    region = 'us-west-2'
  }

  // e.g. https://s3.console.aws.amazon.com/s3/buckets/bugseq-dev-nextflow-tmp-ca-central-1?prefix=nextflow/8f/be85cf64c6decd382d319f6248eeb4/
  const params = new URLSearchParams({
    prefix: parsed.prefix ?? '/',
    region
  })
  const linkWithoutRole = `https://s3.console.aws.amazon.com/s3/buckets/${parsed.bucket}?${params.toString()}`
  return getAWSLinkWithRole(linkWithoutRole, 'prod_ro')
}

export const getBatchDashboardLink = (jobId: string, region: string): string => {
  const url = new URL('/batch/home', `https://${region}.console.aws.amazon.com`)
  url.hash = `jobs/detail/${jobId}`
  return getAWSLinkWithRole(url.toString(), 'prod_ro')
}

export const getBatchLogsLink = (logStreamName: string, region: string): string => {
  const url = new URL('/cloudwatch/home', `https://${region}.console.aws.amazon.com`)
  url.hash = `logEventViewer:group=/aws/batch/job;stream=${logStreamName}`
  return getAWSLinkWithRole(url.toString(), 'prod_ro')
}

export const getECRLink = (image: string): string => {
  // 642025892278.dkr.ecr.ca-central-1.amazonaws.com/bugseq/accessories/boto3:prod-933760c8
  const registrySep = image.indexOf('/')
  const [registry, imageWithTag] = [image.slice(0, registrySep), image.slice(registrySep + 1)]
  const awsAccountId = registry.split('.')[0]
  const region = registry.split('.')[3]
  const imageName = imageWithTag.split(':')[0]

  // https://ca-central-1.console.aws.amazon.com/ecr/repositories/private/642025892278/bugseq/accessories/boto3?region=ca-central-1
  const url = new URL(`/ecr/repositories/private/${awsAccountId}/${imageName}`, `https://${region}.console.aws.amazon.com`)
  return getAWSLinkWithRole(url.toString(), 'prod_ro')
}

export const getUserProfileLink = (userId: string): string => {
  return `/app/main/admin/users/edit/${userId}`
}

export const isInApp = (router): boolean => {
  return router.path.indexOf('app/main') !== -1
}

export function urlSafeBase64Encode (obj: any): string {
  const jsonString = JSON.stringify(obj)
  const base64String = btoa(jsonString)
  const urlSafeString = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  return urlSafeString
}

export function urlSafeBase64Decode (encodedString: string): any {
  const base64String = encodedString.replace(/-/g, '+').replace(/_/g, '/')
  const paddedBase64String = base64String.padEnd(base64String.length + (4 - base64String.length % 4) % 4, '=')
  const jsonString = atob(paddedBase64String)
  const decodedObject = JSON.parse(jsonString)
  return decodedObject
}

export interface InviteLinkParameters {
  firstName: string
  lastName: string
  affiliation: string
  signupcode: string
  terms: boolean
}

export function debounce (delay: number) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value
    let timeoutId: number | null

    descriptor.value = function (...args: any[]) {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      timeoutId = window.setTimeout(() => {
        originalMethod.apply(this, args)
      }, delay)
    }

    return descriptor
  }
}
