// errors really don't play well with openapi-fetch
// revisit when we upgrade openapi-fetch (last noted Jan 11, 2024 - there is an updated version available)
/* eslint @typescript-eslint/no-throw-literal: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */
import createClient from 'openapi-fetch'
import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { getApiUrl } from '@/env'
import { components, paths } from '@/lib/api/api'
import { getAuthHeaders } from '@/lib/api/auth'
import { parseNextflowTaskRecord, parseBatchJob, parseJobRunResponse, parseJobRunAdminListResponse, parseUser, parseBillingAccountSampleCreditAdminResponse, parseBillingAccountUserCreditAdminResponse } from '@/lib/api/parse'
import { BugSeqApiError, isRawBugSeqApiError } from '@/lib/api/errors'
import { State } from '../state'
import { AdminState } from './state'
import {
  commitSetBatchJobs,
  commitSetJobRun,
  commitSetUser
} from './mutations'
import {
  dispatchCheckApiError
} from '../main/actions'
import {
  commitAddNotification,
  commitRemoveNotification
} from '../main/mutations'

type MainContext = ActionContext<AdminState, State>

const { GET, POST, PUT, DELETE } = createClient<paths>({ baseUrl: getApiUrl() })

export const actions = {
  async actionGetUser (context: MainContext, { id }: { id: string }) {
    try {
      const { data, error } = await GET('/v1/admin/users/{user_id}', {
        headers: await getAuthHeaders(),
        params: { path: { user_id: id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUser(context, data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetUsers (
    context: MainContext,
    payload: paths['/v1/admin/users/']['get']['parameters']['query']
  ): Promise<Array<components['schemas']['UserResponse']>> {
    try {
      const { data, error } = await GET('/v1/admin/users/', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.map(parseUser)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetJobs (
    context: MainContext,
    payload: paths['/v1/admin/jobs/admin']['get']['parameters']['query']
  ) {
    try {
      let { data, error } = await GET('/v1/admin/jobs/admin', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = data!.map(parseJobRunAdminListResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetBatchJobs (context: MainContext) {
    try {
      const { data, error } = await GET('/v1/admin/jobs/batch', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetBatchJobs(context, data!.map(parseBatchJob))
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetAdminJobInfo (
    context: MainContext,
    { jobId }: { jobId: string }
  ) {
    try {
      const { data, error } = await GET('/v1/admin/jobs/admin/{job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: jobId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.job = parseJobRunResponse(data!.job)
      data!.batch_jobs = data!.batch_jobs.map(parseBatchJob)
      return data!
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetNextflowTasks (
    context: MainContext,
    { jobId, params }: { jobId: string, params: paths['/v1/admin/jobs/admin/{job_id}/nextflow-tasks']['get']['parameters']['query'] }
  ) {
    try {
      const { data, error } = await GET('/v1/admin/jobs/admin/{job_id}/nextflow-tasks', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: jobId }, query: params }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.tasks = data!.tasks.map(parseNextflowTaskRecord)
      return data!
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetBatchTasks (
    context: MainContext,
    { jobId, params }: { jobId: string, params: paths['/v1/admin/jobs/admin/{job_id}/batch-tasks']['get']['parameters']['query'] }
  ) {
    try {
      const { data, error } = await GET('/v1/admin/jobs/admin/{job_id}/batch-tasks', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: jobId }, query: params }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.map(parseBatchJob)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionCancelBatchJob (
    context: MainContext,
    payload: { awsBatchJobId: string }
  ) {
    const loadingNotification = {
      content: 'Killing!!!!!!',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await DELETE('/v1/admin/jobs/batch/{aws_batch_job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { aws_batch_job_id: payload.awsBatchJobId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Job killed',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionRunJob (
    context: MainContext,
    payload: { id: string, params: components['schemas']['RunJobRunRequest'] }
  ) {
    const loadingNotification = { content: 'running', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/admin/process/run/{job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id } },
        body: payload.params
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Job kicked off',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionUpdateJobRun (
    context: MainContext,
    payload: { id: string, jobRun: components['schemas']['JobRunUpdateRequest'] }
  ) {
    const loadingNotification = { content: 'updating', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      let { data, error } = await PUT('/v1/admin/jobs/{job_id}', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id } },
        body: payload.jobRun
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = parseJobRunResponse(data!)
      commitSetJobRun(context, data)
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Job updated successfully',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionCompleteJob (
    context: MainContext,
    payload: { id: string, options: paths['/v1/admin/jobs/{job_id}/complete']['put']['parameters']['query'] }
  ) {
    const loadingNotification = { content: 'Sending', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await PUT('/v1/admin/jobs/{job_id}/complete', {
        headers: await getAuthHeaders(),
        params: { path: { job_id: payload.id }, query: payload.options }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Sent!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetAdminJobInputs (
    context: MainContext,
    payload: { jobId: string }
  ) {
    try {
      const { data, error } = await GET('/v1/admin/process/{job_id}/inputs', {
        headers: await getAuthHeaders(),
        params: {
          path: { job_id: payload.jobId }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.inputs.map(input => ({ ...input, s3_restore_request_date: (input.s3_restore_request_date != null) ? new Date(input.s3_restore_request_date) : null }))
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionAdminRestoreJobInputs (
    context: MainContext,
    payload: { jobId: string }
  ) {
    const loadingNotification = { content: 'Initiating Restore', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/admin/process/{job_id}/inputs/restore', {
        headers: await getAuthHeaders(),
        params: {
          path: { job_id: payload.jobId }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Restore initiated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionUpdateUser (
    context: MainContext,
    payload: { id: string, user: components['schemas']['UserUpdateAdminRequest'] }
  ) {
    const loadingNotification = { content: 'saving', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      const { data, error } = await PUT('/v1/admin/users/{user_id}', {
        headers: await getAuthHeaders(),
        params: {
          path: { user_id: payload.id }
        },
        body: payload.user
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUser(context, data!)
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'User successfully updated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGenerateApiKey (context: MainContext, payload: { id: string }) {
    const loadingNotification = { content: 'Generating', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/admin/users/{user_id}/apikeys', {
        headers: await getAuthHeaders(),
        params: {
          path: { user_id: payload.id }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Generated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetMetrics (
    context: MainContext
  ) {
    try {
      const { data, error } = await GET('/v1/admin/metrics/', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.users = data!.users.map(parseUser)
      data!.jobs = data!.jobs.map(parseJobRunAdminListResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetBillingSampleCredits (context: MainContext, { billingAccountId }: { billingAccountId: string }) {
    try {
      const { data, error } = await GET('/v1/admin/billing/{billing_account_id}/credits/sample', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: billingAccountId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountSampleCreditAdminResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionAddBillingSampleCredits (
    context: MainContext,
    payload: { billingAccountId: string, body: components['schemas']['BillingAccountSampleCreditCreateAdminRequest'] }
  ) {
    try {
      const { data, error } = await POST('/v1/admin/billing/{billing_account_id}/credits/sample', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: payload.billingAccountId } },
        body: payload.body
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseBillingAccountSampleCreditAdminResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetBillingUserCredits (context: MainContext, { billingAccountId }: { billingAccountId: string }) {
    try {
      const { data, error } = await GET('/v1/admin/billing/{billing_account_id}/credits/user', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: billingAccountId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountUserCreditAdminResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionAddBillingUserCredits (
    context: MainContext,
    payload: { billingAccountId: string, body: components['schemas']['BillingAccountUserCreditCreateAdminRequest'] }
  ) {
    try {
      const { data, error } = await POST('/v1/admin/billing/{billing_account_id}/credits/user', {
        headers: await getAuthHeaders(),
        params: { path: { billing_account_id: payload.billingAccountId } },
        body: payload.body
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseBillingAccountUserCreditAdminResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  }
}

const { dispatch } = getStoreAccessors<AdminState, State>('')

export const dispatchGetUsers = dispatch(actions.actionGetUsers)
export const dispatchGetUser = dispatch(actions.actionGetUser)
export const dispatchGetJobs = dispatch(actions.actionGetJobs)
export const dispatchGetBatchJobs = dispatch(actions.actionGetBatchJobs)
export const dispatchGetAdminJobInfo = dispatch(actions.actionGetAdminJobInfo)
export const dispatchGetNextflowTasks = dispatch(actions.actionGetNextflowTasks)
export const dispatchGetBatchTasks = dispatch(actions.actionGetBatchTasks)
export const dispatchCancelBatchJob = dispatch(actions.actionCancelBatchJob)
export const dispatchRunJob = dispatch(actions.actionRunJob)
export const dispatchUpdateJobRun = dispatch(actions.actionUpdateJobRun)
export const dispatchCompleteJob = dispatch(actions.actionCompleteJob)
export const dispatchGetAdminJobInputs = dispatch(
  actions.actionGetAdminJobInputs
)
export const dispatchAdminRestoreJobInputs = dispatch(
  actions.actionAdminRestoreJobInputs
)
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser)
export const dispatchGenerateApiKey = dispatch(actions.actionGenerateApiKey)
export const dispatchGetMetrics = dispatch(actions.actionGetMetrics)
export const dispatchGetBillingSampleCredits = dispatch(actions.actionGetBillingSampleCredits)
export const dispatchAddBillingSampleCredits = dispatch(actions.actionAddBillingSampleCredits)
export const dispatchGetBillingUserCredits = dispatch(actions.actionGetBillingUserCredits)
export const dispatchAddBillingUserCredits = dispatch(actions.actionAddBillingUserCredits)
