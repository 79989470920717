// Import Component hooks before component definitions
import './component-hooks'
import { Amplify } from 'aws-amplify'
import Vue from 'vue'
import './plugins/vuetify'
import './plugins/vee-validate'
import App from './App.vue'
import router from './router'
import {
  getCognitoPoolData,
  getCognitoRedirectURL,
  getCognitoRedirectSignOutURL,
  getCognitoURL
} from '@/env'
import store from '@/store'
import './registerServiceWorker'
import 'vuetify/dist/vuetify.min.css'
import './style.css'
import VueGtag from 'vue-gtag'
import AsyncComputed from 'vue-async-computed'
import VueMeta from 'vue-meta'

const cognitoPoolData = getCognitoPoolData()

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#top-level-configuration
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: cognitoPoolData.userPoolId,
      userPoolClientId: cognitoPoolData.clientId,

      loginWith: {
        oauth: {
          domain: getCognitoURL(),
          scopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [getCognitoRedirectURL()],
          redirectSignOut: [getCognitoRedirectSignOutURL()],
          responseType: 'code'
        },
        email: true
      }
    }
  }
})

Vue.config.productionTip = false
Vue.use(AsyncComputed)
Vue.use(
  VueGtag,
  {
    config: {
      id: 'UA-129345906-3',
      params: {
        send_page_view: true
      }
    },
    appName: 'My application',
    pageTrackerScreenviewEnabled: true
  },
  router
)
Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
