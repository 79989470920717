
import { Vue, Component } from "vue-property-decorator";
import { readDashboardShowDrawer } from "@/store/main/getters";
import { commitSetDashboardShowDrawer } from "@/store/main/mutations";
import { isInApp } from "@/utils";

@Component
export default class Toolbar extends Vue {
  private navSections = [
    {
      section: "About",
      href: "/",
      links: [
        {
          title: "The BugSeq Advantage",
          href: "/#whybugseq",
        },
        {
          title: "How It Works",
          href: "/#about",
        },
        {
          title: "Contact Us",
          href: "/#contact",
        },
        {
          title: "Pricing",
          href: "/pricing",
        },
      ],
    },
    {
      section: "Solutions",
      href: "/solutions",
      links: [
        {
          title: "Metagenomics",
          href: "/solutions#metagenomics",
        },
        {
          title: "Hospital Acquired Infections",
          href: "/solutions#hai",
        },
        {
          title: "16S/ITS",
          href: "/solutions#sixteens",
        },
        {
          title: "Viral Surveillance",
          href: "/solutions#viral-surveillance",
        },
        {
          title: "Pathogen Pipelines",
          href: "/pathogen-pipelines",
        },
      ],
    },
    {
      section: "Data Analysis",
      href: "/analysis",
      links: [
        {
          title: "Taxonomic Classification",
          href: "/analysis#taxonomic-classification",
        },
        {
          title: "Curated Database",
          href: "/analysis#databases",
        },
        {
          title: "Antimicrobial Resistance",
          href: "/analysis#amr",
        },
        {
          title: "Outbreak Analysis",
          href: "/analysis#outbreak-analysis",
        },
        {
          title: "Plasmid Detection",
          href: "/analysis#plasmid-detection",
        },
        {
          title: "Quality Control",
          href: "/analysis#quality-control",
        },
        {
          title: "Subtyping",
          href: "/analysis#subtyping",
        },
        {
          title: "Pathogen Identification",
          href: "/analysis#pathogen-identification",
        },
      ],
    },
    {
      section: "Resources",
      links: [
        {
          title: "Publications",
          href: "/publications",
        },
        {
          title: "Documentation",
          href: "https://docs.bugseq.com",
          target: "_blank",
        },
        {
          title: "Blog",
          href: "https://docs.bugseq.com/blog",
          target: "_blank",
        },
        {
          title: "Conferences",
          href: "/resources/conferences",
        },
        {
          title: "Support",
          href: "/resources/support",
        },
      ],
    },
  ]

  public isInApp() {
    return isInApp(this.$route);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }
}
