import { fetchAuthSession } from 'aws-amplify/auth'

export class CognitoSessionError extends Error {}

export async function getAuthHeaders (): Promise<Headers> {
  const headers = new Headers()

  try {
    const session = await fetchAuthSession()
    const token = session?.tokens?.accessToken.toString() as string | null
    if (token == null) {
      throw new CognitoSessionError('token not found')
    }
    headers.set('Authorization', `Bearer ${token}`)
  } catch (e) {
    throw new CognitoSessionError('getting cognito access token', { cause: e })
  }

  return headers
}
