// errors really don't play well with openapi-fetch
// revisit when we upgrade openapi-fetch (last noted Jan 11, 2024 - there is an updated version available)
/* eslint @typescript-eslint/no-throw-literal: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */
import { signOut } from 'aws-amplify/auth'
import createClient from 'openapi-fetch'
import router from '@/router'
import { getStoreAccessors } from 'typesafe-vuex'
import { ActionContext } from 'vuex'
import { getApiUrl } from '@/env'
import { components, paths } from '@/lib/api/api'
import { BugSeqApiError, isRawBugSeqApiError } from '@/lib/api/errors'
import { getAuthHeaders, CognitoSessionError } from '@/lib/api/auth'
import {
  parseBillingAccountSampleCreditResponse,
  parseBillingAccountUserCreditResponse,
  parseJobRunResponse,
  parseJobRunResultsResponse,
  parseUser,
  parseListBasespaceProjectsResponse,
  parseListBasespaceSamplesResponse,
  parseAnalysisDataSummaryResponse,
  parseInviteResponse
} from '@/lib/api/parse'
import { State } from '../state'
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetUserProfile,
  commitSetJobResults,
  commitSetLabs,
  commitAddLabInvites,
  commitSetAnalysisDataSummary,
  commitSetAnalysisDataGenomeIgnoreSummary,
  commitLabMembershipUpdate
} from './mutations'
import { AppNotification, MainState } from './state'

type MainContext = ActionContext<MainState, State>

export async function redirectToSignIn (redirect: string = '/app/main/dashboard'): Promise<void> {
  try {
    await router.push({ path: '/login', query: { redirect } })
  } catch (e) {
    // this will happen if a page loads and fires multiple reqs
    // and they all cause a redirect to login
  }
}

export async function redirectToSignUp (redirect: string = '/app/main/dashboard'): Promise<void> {
  try {
    await router.push({ path: '/register', query: { redirect } })
  } catch (e) {
    // this will happen if a page loads and fires multiple reqs
    // and they all cause a redirect to login
  }
}

const { GET, POST, PUT, DELETE } = createClient<paths>({ baseUrl: getApiUrl() })

export const actions = {
  async actionCreateOpenUser (
    context: MainContext,
    payload: components['schemas']['UserCreateRequest']
  ) {
    const loadingNotification = {
      content: 'Updating...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/users/open', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'All set!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e // rethrow so the user can fix the form
    }
  },
  async actionAddNotification (context: MainContext, payload) {
    commitAddNotification(context, payload)
  },
  async actionGetUserProfile (context: MainContext) {
    try {
      const { data, error } = await GET('/v1/users/me', { headers: await getAuthHeaders() })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUserProfile(context, parseUser(data!))
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionGetSampleCredits (
    context: MainContext,
    payload: {
      billingAccountId: string
      params: paths['/v1/billing/{billing_account_id}/credits/sample']['get']['parameters']['query']
    }
  ) {
    try {
      const { data, error } = await GET(
        '/v1/billing/{billing_account_id}/credits/sample',
        {
          headers: await getAuthHeaders(),
          params: {
            query: payload.params,
            path: { billing_account_id: payload.billingAccountId }
          }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountSampleCreditResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetUserCredits (
    context: MainContext,
    payload: {
      billingAccountId: string
      params: paths['/v1/billing/{billing_account_id}/credits/user']['get']['parameters']['query']
    }
  ) {
    try {
      const { data, error } = await GET(
        '/v1/billing/{billing_account_id}/credits/user',
        {
          headers: await getAuthHeaders(),
          params: {
            query: payload.params,
            path: { billing_account_id: payload.billingAccountId }
          }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountUserCreditResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionRunCascade (
    context: MainContext,
    payload: components['schemas']['CascadeRunSubmitRequest']
  ) {
    const loadingNotification = {
      content: 'Submitting job',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/process/', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Job successfully submitted - Results will be emailed to you.',
        color: 'success'
      })
      await router.push('/app/main/submitted')
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionSubmitForm (
    context: MainContext,
    payload: { form: components['schemas']['Form'], body: any }
  ) {
    try {
      const { error } = await POST(
        '/v1/contact/hs',
        {
          body: payload.body,
          params: { query: { form: payload.form } }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionDropboxSubmit (
    context: MainContext,
    payload: components['schemas']['DropboxSubmitRequest']
  ) {
    const loadingNotification = {
      content: 'Submitting',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/files/dropbox', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Submitted',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionUpdateUserProfile (
    context: MainContext,
    payload: components['schemas']['UserUpdateRequest']
  ) {
    const loadingNotification = { content: 'saving', showProgress: true }
    commitAddNotification(context, loadingNotification)
    try {
      const { data, error } = await PUT('/v1/users/me', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUserProfile(context, parseUser(data!))
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Profile successfully updated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionCheckLoggedIn (context: MainContext) {
    const { data, error } = await GET('/v1/users/me', { headers: await getAuthHeaders() })
    if (error !== undefined) {
      if (isRawBugSeqApiError(error)) {
        throw new BugSeqApiError(error.detail)
      }
      throw error
    }
    commitSetUserProfile(context, parseUser(data!))
  },
  async actionUserLogOut (context: MainContext) {
    commitAddNotification(context, { content: 'Logged out', color: 'success' })
    await signOut()
    await router.push('/')
  },
  async actionCheckApiError (context: MainContext, payload: any) {
    if ((payload instanceof CognitoSessionError) || (payload instanceof BugSeqApiError && payload.message === 'User not found')) {
      const existingParams = new URLSearchParams(window.location.search)

      // don't clobber redirect if it exists, and multiple of these are racing
      let redirect = existingParams.get('redirect')
      if (redirect === null) {
        redirect = router.currentRoute.path
      }
      await redirectToSignIn(redirect)
      return
    }
    let errMsg = 'Error'
    if (payload instanceof BugSeqApiError) {
      errMsg += `: ${payload.message}`
    }
    commitAddNotification(context, {
      color: 'error',
      content: errMsg
    })
  },
  async removeNotification (
    context: MainContext,
    payload: { notification: AppNotification, timeout: number }
  ) {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification)
        resolve(true)
      }, payload.timeout)
    })
  },
  async actionGetUserRuns (
    context: MainContext,
    payload: paths['/v1/jobs/']['get']['parameters']['query']
  ) {
    try {
      let { data, error } = await GET('/v1/jobs/', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      data = data!
      data.job_runs = data.job_runs.map(parseJobRunResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionGetResults (
    context: MainContext,
    { jobId, free, admin }: { jobId: string, free: boolean, admin: boolean }
  ) {
    const params = { path: { job_id: jobId } }

    try {
      let data, error
      if (free) {
        ({ data, error } = await GET('/v1/academic/process/{job_id}/results', {
          params
        }))
      } else if (admin) {
        ({ data, error } = await GET('/v1/admin/process/{job_id}/results', {
          headers: await getAuthHeaders(),
          params
        }))
      } else {
        ({ data, error } = await GET('/v1/process/{job_id}/results', {
          headers: await getAuthHeaders(),
          params
        }))
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = parseJobRunResultsResponse(data)
      commitSetJobResults(context, {
        jobId,
        results: data
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionGetFileLink (
    context: MainContext,
    payload: { jobId: string, filename: string, free: boolean, admin: boolean }
  ) {
    const params = {
      path: { job_id: payload.jobId },
      query: { filename: payload.filename }
    }

    try {
      let error, data
      if (payload.free) {
        ({ data, error } = await GET('/v1/academic/process/{job_id}/results/download', {
          params
        }))
      } else if (payload.admin) {
        ({ data, error } = await GET('/v1/admin/process/{job_id}/results/download', {
          headers: await getAuthHeaders(),
          params
        }))
      } else {
        ({ data, error } = await GET('/v1/process/{job_id}/results/download', {
          headers: await getAuthHeaders(),
          params
        }))
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionRunCascadeAcademic (
    context: MainContext,
    payload: components['schemas']['AcademicCascadeRunSubmitRequest']
  ) {
    const loadingNotification = {
      content: 'Submitting job',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/academic/process/', { body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Job successfully submitted - Results will be emailed to you.',
        color: 'success'
      })
      await router.push('/free/submitted')
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionCreateLab (
    context: MainContext,
    payload: components['schemas']['OrganizationCreateRequest']
  ) {
    const loadingNotification = {
      content: 'Creating...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { data, error } = await POST('/v1/organization/', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Created!',
        color: 'success'
      })
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionUpdateLab (
    context: MainContext,
    payload: { id: string, lab: components['schemas']['OrganizationUpdate'] }
  ) {
    const loadingNotification = {
      content: 'Updating...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await PUT('/v1/organization/{org_id}', {
        headers: await getAuthHeaders(),
        body: payload.lab,
        params: { path: { org_id: payload.id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Updated!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionUpdateLabMembership (
    context: MainContext,
    payload: { id: string, payload: components['schemas']['OrganizationMembershipUpdate'] }
  ) {
    const loadingNotification = {
      content: 'Updating...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { data, error } = await PUT('/v1/organization/{org_id}/members/me', {
        headers: await getAuthHeaders(),
        body: payload.payload,
        params: { path: { org_id: payload.id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      commitLabMembershipUpdate(context, {
        labId: payload.id,
        preferences: data!
      })

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Updated!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionRemoveLabMember (
    context: MainContext,
    payload: { labId: string, userId: string }
  ) {
    const loadingNotification = {
      content: 'Removing...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await DELETE('/v1/organization/{org_id}/members/{user_id}', {
        headers: await getAuthHeaders(),
        params: { path: { org_id: payload.labId, user_id: payload.userId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Removed!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionClearLabData (
    context: MainContext,
    payload: { labId: string }
  ) {
    const loadingNotification = {
      content: 'Deleting...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await DELETE('/v1/organization/{org_id}/data', {
        headers: await getAuthHeaders(),
        params: { path: { org_id: payload.labId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Deleted!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionDeleteLab (
    context: MainContext,
    payload: { labId: string }
  ) {
    const loadingNotification = {
      content: 'Deleting...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await DELETE('/v1/organization/{org_id}', {
        headers: await getAuthHeaders(),
        params: { path: { org_id: payload.labId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Deleted!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionGetLabMembership (context: MainContext) {
    try {
      const { data, error } = await GET('/v1/organization/membership', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetLabs(context, data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionGetLabInvites (context: MainContext, labId: string) {
    try {
      const { data, error } = await GET('/v1/organization/{org_id}/invites', {
        headers: await getAuthHeaders(),
        params: {
          path: { org_id: labId }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseInviteResponse)
      commitAddLabInvites(context, data!.items)
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionInviteToLab (
    context: MainContext,
    payload: { organizationId: string, inviteeEmail: string, memberRole: components['schemas']['OrgMemberRole'] }
  ) {
    const loadingNotification = {
      content: 'Inviting...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { data, error } = await POST('/v1/organization/{org_id}/invites', {
        headers: await getAuthHeaders(),
        params: {
          path: { org_id: payload.organizationId }
        },
        body: { invitee_email: payload.inviteeEmail, member_role: payload.memberRole }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Sent!',
        color: 'success'
      })
      commitAddLabInvites(context, [parseInviteResponse(data!)])
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionAcceptLabInvite (
    context: MainContext,
    payload: { inviteId: string }
  ) {
    const loadingNotification = {
      content: 'Joining...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/organization/invites/{invite_id}/accept', {
        headers: await getAuthHeaders(),
        params: { path: { invite_id: payload.inviteId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Joined!',
        color: 'success'
      })

      // joining a lab can update the user (e.g. their region), so refresh the user
      await dispatchGetUserProfile(context)

      // could go to the lab page, but that doesn't seem useful.
      await router.push('/app/main/history')
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionShareJob (
    context: MainContext,
    payload: {
      jobId: string
      sharedViaLabId: string
      sharedWithUserId?: string
      sharedWithLabId?: string
    }
  ) {
    const loadingNotification = {
      content: 'Sharing...',
      showProgress: true
    }
    commitAddNotification(context, loadingNotification)
    try {
      const { error } = await POST('/v1/jobs/{job_id}/access', {
        headers: await getAuthHeaders(),
        body: {
          shared_via_organization_id: payload.sharedViaLabId,
          shared_with_user_id: payload.sharedWithUserId,
          shared_with_organization_id: payload.sharedWithLabId
        },
        params: { path: { job_id: payload.jobId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: 'Shared!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionGetBasespaceProjects (
    context: MainContext,
    payload: paths['/v1/basespace/projects']['get']['parameters']['query']
  ) {
    try {
      const { data, error } = await GET('/v1/basespace/projects', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseListBasespaceProjectsResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetBasespaceSamples (
    context: MainContext,
    payload: {
      projectId: string
      query: paths['/v1/basespace/projects/{project_id}/samples']['get']['parameters']['query']
    }
  ) {
    try {
      const { data, error } = await GET(
        '/v1/basespace/projects/{project_id}/samples',
        {
          headers: await getAuthHeaders(),
          params: { path: { project_id: payload.projectId }, query: payload.query }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseListBasespaceSamplesResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async actionGetAnalysisDataSummary (context: MainContext) {
    try {
      let { data, error } = await GET('/v1/explore/summary', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      data = data!.map(parseAnalysisDataSummaryResponse)
      commitSetAnalysisDataSummary(context, data)
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async actionUpdateGenomeSummaryIgnore (
    context: MainContext,
    payload: { jobId: string, payload: components['schemas']['GenomeSummaryIgnoreUpdateRequest'] }
  ) {
    const params = { path: { job_id: payload.jobId } }
    try {
      const { error } = await PUT('/v1/explore/{job_id}/genomesummaryignore', {
        headers: await getAuthHeaders(),
        body: payload.payload,
        params
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      commitSetAnalysisDataGenomeIgnoreSummary(context, payload)
      commitAddNotification(context, {
        content: 'Updated!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  }
}

const { dispatch } = getStoreAccessors<MainState | any, State>('')

export const dispatchAddNotification = dispatch(actions.actionAddNotification)
export const dispatchCheckApiError = dispatch(actions.actionCheckApiError)
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn)
export const dispatchSubmitForm = dispatch(actions.actionSubmitForm)
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile)
export const dispatchGetSampleCredits = dispatch(actions.actionGetSampleCredits)
export const dispatchGetUserCredits = dispatch(actions.actionGetUserCredits)
export const dispatchCreateOpenUser = dispatch(actions.actionCreateOpenUser)
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut)
export const dispatchUpdateUserProfile = dispatch(
  actions.actionUpdateUserProfile
)
export const dispatchRemoveNotification = dispatch(actions.removeNotification)
export const dispatchRunCascade = dispatch(actions.actionRunCascade)
export const dispatchGetUserRuns = dispatch(actions.actionGetUserRuns)
export const dispatchGetResults = dispatch(actions.actionGetResults)
export const dispatchRunCascadeAcademic = dispatch(actions.actionRunCascadeAcademic)
export const dispatchGetFileLink = dispatch(actions.actionGetFileLink)
export const dispatchCreateLab = dispatch(actions.actionCreateLab)
export const dispatchUpdateLab = dispatch(actions.actionUpdateLab)
export const dispatchUpdateLabMembership = dispatch(actions.actionUpdateLabMembership)
export const dispatchRemoveLabMember = dispatch(actions.actionRemoveLabMember)
export const dispatchClearLabData = dispatch(actions.actionClearLabData)
export const dispatchDeleteLab = dispatch(actions.actionDeleteLab)
export const dispatchGetLabMembership = dispatch(
  actions.actionGetLabMembership
)
export const dispatchGetLabInvites = dispatch(
  actions.actionGetLabInvites
)
export const dispatchInviteToLab = dispatch(actions.actionInviteToLab)
export const dispatchAcceptLabInvite = dispatch(actions.actionAcceptLabInvite)
export const dispatchShareJob = dispatch(actions.actionShareJob)
export const dispatchGetBasespaceSamples = dispatch(
  actions.actionGetBasespaceSamples
)
export const dispatchGetBasespaceProjects = dispatch(
  actions.actionGetBasespaceProjects
)
export const dispatchGetAnalysisDataSummary = dispatch(
  actions.actionGetAnalysisDataSummary
)
export const dispatchUpdateGenomeSummaryIgnore = dispatch(
  actions.actionUpdateGenomeSummaryIgnore
)
export const dispatchDropboxSubmit = dispatch(
  actions.actionDropboxSubmit
)
