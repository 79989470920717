<template>
  <v-footer height="auto" inset app absolute>
    <v-container fluid>
      <v-layout wrap align-center justify-center row fill-height>
        <v-flex xs12 md10>
          <v-container fluid>
            <v-layout wrap justify-center row>
              <v-layout wrap row pt-1 style="padding-bottom: 70px;">
                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">
                    Platform
                  </p>
                  <div
                    v-for="(item, i) in FooterData.Platform"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>

                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">
                    Resources
                  </p>
                  <div
                    v-for="(item, i) in FooterData.Resources"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>

                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">
                    About Us
                  </p>
                  <div
                    v-for="(item, i) in FooterData.AboutUs"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>

                <v-flex xs6 md3 class="py-2 card">
                  <p class="google-font mb-0 header font-weight-bold">Legal</p>
                  <div
                    v-for="(item, i) in FooterData.Legal"
                    :key="i"
                    class="mt-2"
                  >
                    <a :href="item.Link" class="google-font link">{{
                      item.LinkName
                    }}</a
                    ><br />
                  </div>
                </v-flex>
              </v-layout>
            </v-layout>
            <v-divider></v-divider>
            <v-layout align-center justify-space-between row class="mb-0 mt-2">
              <v-flex>
                <img
                  :src="require('@/assets/img/home/footer-logo.png')"
                  class="footer-logo"
                />
              </v-flex>
              <v-flex class="text-xs-right">
                ©BugSeq 2024
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import FooterData from "@/assets/data/footer.json";
import Footer from "@/components/home/Footer";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      FooterData,
    };
  },
};
</script>

<style>
.larger-font-size {
  font-size: 110%;
}
.mobile-font-size {
  font-size: 70%;
}
</style>

<style scoped>
.link {
  color: #424242; /* grey darken-3 */
  text-decoration: none;
}
.header {
  color: black;
  font-size: 120%;
  font-weight: 300;
}
.card {
  padding: 0% 0% 0% 7%;
}

.footer-logo {
  height: 20px;
}
</style>
