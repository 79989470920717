module.exports = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
  },
  {
    path: '/solutions',
    component: () =>
      import(/* webpackChunkName: "solutions" */ './views/Solutions.vue')
  },
  {
    path: '/analysis',
    component: () => import(/* webpackChunkName: "analysis" */ './views/Analysis.vue')
  },
  {
    path: '/resources',
    redirect: '/resources/conferences',
    component: () => import(/* webpackChunkName: "resources" */ './views/GenericCardPage.vue'),
    children: [
      {
        path: 'conferences',
        name: 'conferences',
        component: () =>
          import('./views/resources/Conferences.vue')
      },
      {
        path: 'support',
        name: 'support',
        component: () =>
          import('./views/resources/Support.vue')
      }
    ]
  },
  {
    path: '/bugseq-demos/2024/demo',
    component: () =>
      import(/* webpackChunkName: "demo" */ './views/demo/Demo.vue'),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/pacbio',
    component: () =>
      import(/* webpackChunkName: "demo" */ './views/demo/PacBio.vue')
  },
  {
    path: '/careers',
    component: () =>
      import(/* webpackChunkName: "careers" */ './views/Careers.vue')
  },
  {
    path: '/pathogen-pipelines',
    component: () => import('./views/PathogenPipelines.vue')
  },
  {
    path:
      '/news/BugSeq-Analysis-Platform-Validated-in-Nanopore-Study-Detecting-Infections',
    component: () => import('./views/PressRelease-12-08-2021.vue')
  },
  {
    path: '/news/BugSeq-BARDA-DRIVe-diagnostics-any-respiratory-virus',
    component: () => import('./views/PressRelease-05-03-2022.vue')
  },
  {
    path: '/news/BugSeq-PGC-Mindanao-partnership',
    component: () => import('./views/PressRelease-2022-09-24.vue')
  },
  {
    path: '/news/BugSeq-PacBio-partnership',
    component: () => import('./views/PressRelease-2023-01-11.vue')
  },
  {
    path: '/news/2024/07/11/BugSeq-BARDA-AI-bioinformatics',
    component: () => import('./views/PressRelease-2024-07-11-barda.vue')
  },
  {
    path: '/free',
    redirect: '/academic',
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/free/results/:job',
    meta: { sitemap: { ignoreRoute: true } },
    redirect: '/academic/results/:job'
  },
  {
    path: '/free/submitted',
    redirect: '/academic/submitted',
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/academic',
    component: () => import('./views/FreeLanding.vue'),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/archived-publication-analysis',
    component: () => import('./views/ArchivedPublicationAnalysis.vue'),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/academic/results/:job',
    meta: { sitemap: { ignoreRoute: true } },
    name: 'academicresults',
    component: () =>
      import(/* webpackChunkName: "main-results" */ './views/FreeResults.vue')
  },
  {
    path: '/academic/submitted',
    meta: { sitemap: { ignoreRoute: true } },
    name: 'academicsubmitted',
    component: () => import('./views/UnauthdSubmitted.vue'),
    props: {
      source: 'academic'
    }
  },
  {
    path: '/basespace/submitted',
    meta: { sitemap: { ignoreRoute: true } },
    name: 'basespacesubmitted',
    component: () => import('./views/UnauthdSubmitted.vue'),
    props: {
      source: 'basespace'
    }
  },
  {
    path: '/pricing',
    component: () => import('./views/Pricing.vue')
  },
  {
    path: '/quote',
    component: () => import('./views/Quote.vue')
  },
  {
    path: '/publications',
    component: () => import('./views/Publications.vue')
  },
  {
    path: '/register',
    component: () => import('./views/Register.vue')
  },
  {
    path: '/login',
    component: () => import('./views/Login.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('./views/ForgotPassword.vue')
  },
  {
    path: '/app',
    component: () =>
      import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
    meta: { sitemap: { ignoreRoute: true } },
    children: [
      {
        path: 'cognito',
        component: () =>
          import(/* webpackChunkName: "cognito" */ './views/Cognito.vue')
      },
      {
        path: 'main',
        component: () =>
          import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
        children: [
          {
            path: 'dashboard',
            component: () =>
              import(
                /* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'
              )
          },
          {
            path: 'history',
            component: () =>
              import(
                /* webpackChunkName: "main-history" */ './views/main/History.vue'
              )
          },
          {
            path: 'explore',
            component: () =>
              import(
                /* webpackChunkName: "main-explore" */ './views/main/DataExplorer.vue'
              )
          },
          {
            path: 'labs',
            component: () =>
              import(
                /* webpackChunkName: "main-labs" */ './views/main/LabsRoot.vue'
              ),
            children: [
              {
                path: '',
                component: () =>
                  import(
                    /* webpackChunkName: "main-labs" */ './views/main/Labs.vue'
                  )
              },
              {
                path: 'new',
                component: () =>
                  import(
                    /* webpackChunkName: "main-labs" */ './views/main/LabNew.vue'
                  )
              },
              {
                path: ':id',
                name: 'labview',
                component: () =>
                  import(
                    /* webpackChunkName: "main-labs" */ './views/main/Lab.vue'
                  )
              }
            ]
          },
          {
            path: 'labs/join/:invite',
            meta: { sitemap: { ignoreRoute: true } },
            component: () =>
              import(
                /* webpackChunkName: "main-labs-invite-accept" */ './views/main/LabInviteAccept.vue'
              )
          },
          {
            path: 'results/:job',
            meta: { sitemap: { ignoreRoute: true } },
            name: 'results',
            component: () =>
              import(
                /* webpackChunkName: "main-results" */ './views/main/Results.vue'
              )
          },
          {
            path: 'dropbox',
            component: () =>
              import(
                /* webpackChunkName: "main-dropbox" */ './views/main/Dropbox.vue'
              )
          },
          {
            path: 'submitted',
            meta: { sitemap: { ignoreRoute: true } },
            name: 'submitted',
            component: () => import('./views/main/Submitted.vue')
          },
          {
            path: 'profile',
            component: () =>
              import(
                /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'
              )
          },
          {
            path: 'admin',
            component: () =>
              import(
                /* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'
              ),
            meta: { sitemap: { ignoreRoute: true } },
            redirect: 'admin/users/all',
            children: [
              {
                path: 'users',
                redirect: 'users/all'
              },
              {
                path: 'users/all',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'
                  )
              },
              {
                path: 'users/invite',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/InviteUser.vue'
                  )
              },
              {
                path: 'users/edit/:id',
                name: 'main-admin-users-edit',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/EditUser.vue'
                  )
              },
              {
                path: 'jobs',
                name: 'main-admin-jobs',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-jobs" */ './views/main/admin/Jobs.vue'
                  )
              },
              {
                path: 'jobs/:jobId/review',
                name: 'main-admin-job-review',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-jobs" */ './views/main/admin/JobReview.vue'
                  )
              },
              {
                path: 'jobs/:jobId/debug',
                name: 'main-admin-job-debug',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-jobs" */ './views/main/admin/JobDebug.vue'
                  )
              },
              {
                path: 'jobs/:jobId/results',
                name: 'main-admin-job-results',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-jobs" */ './views/main/admin/Results.vue'
                  )
              },
              {
                path: 'metrics',
                component: () =>
                  import(
                    /* webpackChunkName: "main-admin-metrics" */ './views/main/admin/Metrics.vue'
                  )
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/*',
    redirect: '/'
  }
]
