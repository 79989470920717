const env: string | undefined = process.env.VUE_APP_ENV

enum BugSeqEnv {
  Dev,
  Stag,
  Prod,
}

export const appName = 'BugSeq'

export function getEnv (): BugSeqEnv {
  if (env === undefined) {
    throw new Error('APP_ENV env var must be defined')
  }

  // these match our backend env values and our aws resources
  switch (env) {
    case 'production':
    case 'prod':
      return BugSeqEnv.Prod
    case 'staging':
    case 'stag':
      return BugSeqEnv.Stag
    case 'development':
    case 'dev':
      return BugSeqEnv.Dev
  }

  throw new Error(`unrecognized env: ${env}`)
}

export function getUrl (): string {
  if (process.env.VUE_APP_URL === undefined) {
    throw new Error('APP_URL must be defined')
  }
  return process.env.VUE_APP_URL
}

export function getApiUrl (): string {
  if (process.env.VUE_APP_API_URL === undefined) {
    throw new Error('APP_API_URL must be defined')
  }
  return process.env.VUE_APP_API_URL
}

export function getCognitoURL (): string {
  if (getEnv() === BugSeqEnv.Prod) {
    return 'auth.bugseq.com'
  }

  return 'devauth.bugseq.com'
}

interface CognitoPoolConfig {
  clientId: string
  userPoolId: string
}

export function getCognitoPoolData (): CognitoPoolConfig {
  if (getEnv() === BugSeqEnv.Prod) {
    return {
      clientId: '1v5jdmkennu0jcevb5s3nie2gq',
      userPoolId: 'us-west-2_T0zTTgjFc'
    }
  }

  return {
    clientId: '9bcvqf7f4bfo8qtbil82qt8dg',
    userPoolId: 'us-west-2_XdgK5mSB0'
  }
}

export function getCognitoRedirectURL (): string {
  return `${getUrl()}/app/cognito`
}

export function getCognitoRedirectSignOutURL (): string {
  return `${getUrl()}`
}
