import { AdminState } from './state'
import { getStoreAccessors } from 'typesafe-vuex'
import { State } from '../state'

export const getters = {
  adminJobs: (state: AdminState) => state.jobs,
  adminIncompleteJobs: (state: AdminState) =>
    state.jobs.filter((j) => j.job_status === 'running'),
  adminBatchJobs: (state: AdminState) => state.batchJobs,
  adminOneUser: (state: AdminState) => (userId: string) => {
    const filteredUsers = state.users.filter((user) => user.id === userId)
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] }
    }
  }
}

const { read } = getStoreAccessors<AdminState, State>('')

export const readAdminOneUser = read(getters.adminOneUser)
export const readAdminJobs = read(getters.adminJobs)
export const readAdminIncompleteJobs = read(getters.adminIncompleteJobs)
export const readAdminBatchJobs = read(getters.adminBatchJobs)
